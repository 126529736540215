import { UserRoles } from "./global"
import {MdContactPhone, MdInbox, MdPayments, MdPeople} from "react-icons/md";

export interface MenuInterface {
    icon: React.FC;
    title: string;
    link: string;
    pathname: string;
    roles?: UserRoles[];
    subMenus?: MenuInterface[];
  }
  
export const MENUS: MenuInterface[] = [
    {
        icon: MdPeople,
        title: "Dashboard",
        link: "/dashboard",
        pathname: "dashboard"
    },
    {
        icon: MdInbox,
        title: "Clients sans facture",
        link: "/sessions",
        pathname: "sessions"
    },
]
