import {useForm} from "react-hook-form";
import CardTable from "../components/card/CardTable";
import {ColumnInterface} from "../components/table/typings";
import {APP_API_URL} from "../config/api";
import useHeaderFunctionPage from "../hooks/useHeaderFunctionPage";
import {useQueries} from "../hooks/useQueries";
import Card from "../components/card/Card";
import {Form} from "../components/form/Form";
import {Select2} from "../components/form/Select";
import {useRef} from "react";
import SimpleButton from "../components/button/SimpleButton";
import {BiSearchAlt2} from "react-icons/bi";
import {CancelButton} from "../components/button/CustomButton";
import moment from "moment/moment";
import {InputDate, InputText} from "../components/form/Input";

export default function ClientPage() {
    /**
     * Hooks
     */
    const {
        baseApiUrl,
        pagination,
        title,
        setPagination,
    } = useHeaderFunctionPage({baseApiUrl: "client", title: "Liste des clients"});

    const mode = useRef(null);
    const product = useRef(null);
    const start = useRef(null);
    const end = useRef(null);
    const fromDate = useRef(moment().subtract(7, "day").format("YYYY-MM-DD"));
    const toDate = useRef(moment().format("YYYY-MM-DD"));
    const reference = useRef(null);


    const queryKey = [
        `${baseApiUrl}s`,
        pagination.page,
        pagination.perPage,
        mode.current,
        product.current,
        start.current,
        end.current,
        fromDate.current,
        toDate.current,
        reference.current,
    ];
    const {
        register,
        reset,
        control,
        handleSubmit,
        formState: {errors},
    } = useForm();
    /**
     * Query
     */    const {data: datas, isLoading} = useQueries(
        APP_API_URL +
        `${baseApiUrl}s?page=${pagination.page}&perPage=${pagination.perPage}
        ${fromDate.current ? `&fromDate=${fromDate.current}` : ""}${
            toDate.current ? `&toDate=${toDate.current}` : ""}
                ${reference.current ? `&reference=${reference.current}` : ""}`,
        queryKey
    );

    const handleSubmitFilterForm = (values: any) => {
        fromDate.current = values.fromDate;
        toDate.current = values.toDate;
        reference.current = values.reference;
    };

    /**
     * Columns Table
     */
    const columns: ColumnInterface[] = [
        {
            title: "Numéro de téléphone",
            key: "phone",
            dataIndex: "phone",
        },
        {
            title: "Nom",
            key: "name",
            dataIndex: "name",
        },
        {
            title: "Reférence",
            key: "reference",
            dataIndex: "reference",
        },
        {
            title: "Date",
            key: "created_at",
            dataIndex: "created_at",
        },
    ];
    /**
     * UI
     */
    return (
        <>
            <Card
                title={"Filtres"}
                extras={[]}
            >
                <Form
                    loading={isLoading}
                    onSubmit={handleSubmit(handleSubmitFilterForm)}
                >
                    <div className={"grid lg:grid-cols-4 md:grid-cols-2 gap-2"}>
                        <div>
                            <InputDate
                                label={"Date de début"}
                                register={register}
                                error={errors?.fromDate}
                                name={"fromDate"}
                                defaultValue={moment().subtract(7, "day").format("YYYY-MM-DD")}
                            />
                        </div>
                        <div>
                            <InputDate
                                label={"Date de fin"}
                                register={register}
                                error={errors?.toDate}
                                name={"toDate"}
                                defaultValue={moment().format("YYYY-MM-DD")}
                            />
                        </div>
                        <div>
                            <InputText
                                label={"Entrer le numéro de référence"}
                                register={register}
                                error={errors?.reference}
                                name={"reference"}
                                required={false}
                            />
                        </div>

                        <div className={"flex items-end gap-2"}>
                            <SimpleButton
                                type={"submit"}
                                className="bg-soft-primary bg-opacity-40 hover:bg-opacity-100 rounded flex items-center justify-center mt-2     "
                            >
                                <BiSearchAlt2 className={"mr-2"}/> Filtrer
                            </SimpleButton>
                        </div>
                    </div>
                </Form>
            </Card>
            <CardTable
                extras={[]}
                columns={columns}
                title={title}
                loading={isLoading}
                datas={datas?.data}
                pagination={pagination}
                setPagination={setPagination}
                tableClassName="text-center"
            />
        </>
    );
}
